import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { PointsTransactionFragment } from 'Apollo/graphql';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import LinkButton from 'Components/LinkButton';
import ModalDrawerBody from 'Components/ModalDrawer/ModalDrawerBody';
import { evaluateOperationSymbol } from 'Modules/PointsTransactionAdmin/pages/PointsTransactionAdminPage/PointsTransactionTable/utils';
import { useAuthUser } from 'Tools/auth/hooks/useAuthUser';
import { valueOrEmpty } from 'Utils/parsers';

interface Props {
  pointsTransaction: PointsTransactionFragment;
  onRemove: (pointsTransaction: PointsTransactionFragment) => void;
  onClose: () => void;
}

const View = ({ pointsTransaction, onRemove, onClose }: Props): JSX.Element => {
  const { t } = useTranslation('pointsTransactionAdmin');
  const authUser = useAuthUser();

  return (
    <ModalDrawerBody>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelId')}>
            {valueOrEmpty(pointsTransaction.id)}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelPosId')}>
            {valueOrEmpty(pointsTransaction.posId)}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelAuthor')}>
            {valueOrEmpty(pointsTransaction.author.username)}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelReason')}>
            {valueOrEmpty(pointsTransaction.reason.name)}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelCreatedAt')}>
            {t('common:date.fullDate', {
              date: new Date(pointsTransaction.createdAt),
            })}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelOperation')}>
            {valueOrEmpty(evaluateOperationSymbol(pointsTransaction.operation))}
          </NotEditableTextClassic>
        </Grid>
        <Grid item xs={12}>
          <NotEditableTextClassic label={t('pointsTransaction.labelAmount')}>
            {valueOrEmpty(pointsTransaction.amount)}
          </NotEditableTextClassic>
        </Grid>
      </Grid>
      {authUser?.permissionsObject.MANAGE_POINTS && (
        <Box mt={4}>
          <LinkButton
            type="button"
            variant="body1"
            onClick={() => onRemove(pointsTransaction)}
            color="secondary"
            disabled={false}
          >
            {t('pointsTransaction.labelDeleteTransaction')}
          </LinkButton>
        </Box>
      )}
      <Box mt={4}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          {t('common:actions.done')}
        </Button>
      </Box>
    </ModalDrawerBody>
  );
};

export default View;
